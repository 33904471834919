<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-4">
                 <div class="vx-logo flex justify-items-center items-center mb-2 pt-4">
                  <img src="@/assets/images/logo/logo.png" alt="logo" class="mx-auto" style="max-height: 25px"/>
                </div>
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4 mt-4 grid justify-items-center">{{ $t('recupere-sua-senha') }}</h4>
                  <p>{{ $t('por-favor-informe-seu-endereco-de-email-') }}</p>
                </div>

                  <vs-input
                    v-validate="'required|email'"
                    data-vv-validate-on="blur"
                    type="email"
                    label-placeholder="Email"
                    v-model="email"
                    class="w-full mb-8"
                  />
                  <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                  <div class="flex flex-wrap justify-center mt-4">
                    <vs-button
                      :disabled="!validateForm"
                      @click="sendForgotInstruction"
                      class="float-right px-4 w-full md:w-auto mt-3 mb-2 md:mt-0 md:mb-0 mr-4 ml-4">{{ $t('email_template_types.reset_password') }}</vs-button>
                    <vs-button type="border" to="/login" class="px-4 w-full md:w-auto mr-4 ml-4">{{ $t('voltar-para-login') }}</vs-button>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: ''
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email !== ''
    }
  },
  methods: {
    sendForgotInstruction() {
      // Loading
      this.$vs.loading()

      const payload = {
        email: this.email,
        notify: this.$vs.notify,
        closeAnimation: this.$vs.loading.close
      }
      this.email = ''
      this.$store.dispatch('auth/forgotPassword', payload)
    }
  }
}
</script>
